import React, { useState, useEffect, useRef } from 'react';

const ResizeTextarea = ({ id, name, placeholder, required }) => {
  // State to hold the value of the textarea
  const [text, setText] = useState('');
  // Ref to the textarea element
  const textareaRef = useRef(null);

  // useEffect hook to update textarea height when text changes
  useEffect(() => {
    const updateTextareaHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = '0';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    updateTextareaHeight();
  }, [text]); // This useEffect hook will re-run whenever the 'text' state changes

  return (
    <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        ref={textareaRef} 
        value={text} // Set value textarea to state variable 'text'
        onChange={(e) => setText(e.target.value)} // Update 'text' state on value changed
        rows={1} 
        style={{ resize: 'none', overflowY: 'hidden' }}
    />
  );
};

export default ResizeTextarea;
