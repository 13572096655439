import React from 'react';
import './FloatingTextImage.css';

const FloatingTextImage = ({ data }) => {
  return (
    <div className='text-image-container'>
        <div className='image-container flex flex-horizontal-center flex-vertical-center'>
            {/* <div className={`test-circle bg-${data.color}`}></div> */}
            <img className={`floating-image floating-image-${data.id}`} src={`/images/${data.image}`} />
        </div>
        <h3 className={`text-${data.color}`}>{data.title}</h3>
        <p dangerouslySetInnerHTML={{__html : data.text}} ></p>
    </div>
  );
};

export default FloatingTextImage;
