
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import React, { useEffect, useRef } from 'react';
import './carousel.css';
import config from '../../config';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import {scrollTo} from "../navbar/Navbar";
// import TabbedContent from '../tabbed-content/TabbedContent';

function Carousel({ contactRef }) {
    const combineItems = [
        { title: 'Starters', text: `Consolidating your pensions with ${config.company} simplifies financial management significantly. By bringing all your accounts under one platform, you gain better control, enhance decision-making, and reduce administrative burden. Experience the convenience and efficiency of consolidated financial management with us today.` },
        { title: 'Mains',  text: `By consolidating all your pensions into one, monitoring your investments becomes effortlessly streamlined. Instead of juggling numerous accounts and balances, you gain a single, comprehensive view of your entire portfolio. This streamlined approach not only saves time but also provides a clearer understanding of your financial position, enabling more informed decision-making.` },
        { title: 'Dessert',   text: `At ${config.company}, we prioritize your convenience and peace of mind. Whether you're transferring a single Pension or consolidating multiple plans, our dedicated support team is readily available to assist you. Reach out to us via phone, email, or secure messaging through our user-friendly App. We're committed to ensuring your transition is smooth and hassle-free, providing reliable support every step of the way.` }
    ];

    const fast = 800;

    const bannerRef = useRef(null);

    useEffect(() => {

        const bannerClick = () => scrollTo(contactRef.current, fast);

        if(bannerRef.current) bannerRef.current.addEventListener("click", bannerClick);

        return () => {
            if(bannerRef.current) bannerRef.current.removeEventListener("click", bannerClick);
        };
    }, []);

    const listItems = combineItems.map((data) =>
        <SwiperSlide  >
            <div className="menu-container">
                <div className="menu-title">
                    {data.title}
                </div>
                <div className="description">
                    {data.text}
                </div>
            </div>
        </SwiperSlide>
    );

    return (

        <div className='container padding-vertical-40'>
            <h2 className='text-ls'>Why Combine with {config.company}?</h2>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={3}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >

                {listItems}
                <SwiperSlide>
                    <div className="menu-container clicker" ref={bannerRef}>
                        <div className="menu-title">

                        </div>
                        <div className="order-now">
                            Order Now
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    );
}

export default Carousel;