import React from 'react';
import './ContactForm.css';
import ResizeTextarea from '../resize-textarea/ResizeTextarea';

function ContactForm() {
  return (
    <div className='container contact-form padding-vertical-40'>
        <h2>Get in touch today!</h2>

        <form action="#" method="post" id="contact_form" onSubmit={Submit}>
            <div className="flex-row">
                <div className="col-50">
                    <input required type="text" name="full_name" id="full_name" placeholder="Enter your full name..." />
                </div>
                <div className="col-50">
                    <input required type="email" name="email" id="email" placeholder="Enter your email..." />
                </div>
                <div className="col-100">
                    <ResizeTextarea required={true} id="message" name="message" placeholder="Enter your message..." />
                </div>
                <div class="col-100">
                    <small><i>You voluntarily choose to provide personal details to us via this website. Personal information will be treated as confidential by us and held in accordance with the appropriate data protection requirements. You agree that such personal information may be used to provide you with details of services and products in writing, by email or by telephone.</i></small>
                    <br /><br />
                    <label for="confirm">I understand the above statement</label>
                    <input required type="checkbox" name="confirm" id="confirm" />
                </div>
                <div className="col-100">
                    <button className="float-right">Send message</button>
                </div>
            </div>
        </form>
    </div>
  );
}

function Submit(event){
    return; // need to implement sub domains
    event.preventDefault();
    const data = new URLSearchParams(new FormData(event.target));

    fetch(`http://data.top-pensions.co.uk/api/contact`, {
        method: 'POST',
        body: data
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      // Handle the data returned from the backend
      console.log(data);
    }).catch(error => {
      // Handle errors
      console.error('Error fetching data:', error);
    });
  
  }

export default ContactForm;