import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import {scrollTo} from '../navbar/Navbar';
import config from '../../config';

const Footer = ({ navRefs, isHome }) => {
    const fast = 500;
  
    const navItemRefs = {
        home    : useRef(null),
        about   : useRef(null),
        contact : useRef(null)
    };

    useEffect(() => {

        const homeClicked    = () => scrollTo(navRefs.home.current, fast);
        const aboutClicked   = () => scrollTo(navRefs.about.current, fast);
        const contactClicked = () => scrollTo(navRefs.contact.current, fast);
        
        const homeRef    = navItemRefs.home.current;
        const aboutRef   = navItemRefs.about.current;
        const contactRef = navItemRefs.contact.current;

        if (homeRef)    homeRef.addEventListener('click', homeClicked);
        if (aboutRef)   aboutRef.addEventListener('click', aboutClicked);
        if (contactRef) contactRef.addEventListener('click', contactClicked);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            if (homeRef)    homeRef.removeEventListener('click', homeClicked);
            if (aboutRef)   aboutRef.removeEventListener('click', aboutClicked);
            if (contactRef) contactRef.removeEventListener('click', contactClicked);
        };
    }, []);

    return (
        <footer>
            <div className='bg-ds'>
                <br /><br /><br />
                <div class="container padding-vertical-40">
                    <div class="flex-row">
                        <div class="col-50">
                            { isHome ?
                                <Link to="/"><h3 className='text-pb' ref={navItemRefs.home}>{config.company}</h3></Link>
                                :
                                <Link to="/"><h3 className='text-pb'>{config.company}</h3></Link>  
                            }
                        </div>
                        { isHome ?
                            <div class="col-50 footer-links">
                                <Link to="/"><div ref={navItemRefs.home} className='text-left footer-link'>Home</div></Link>
                                <Link to="/"><div ref={navItemRefs.about} className='text-left footer-link'>About</div></Link>
                                <Link to="/"><div ref={navItemRefs.contact} className='text-left footer-link'>Contact</div></Link>
                            </div>
                            :
                            <div class="col-50 footer-links">
                                <Link to="/"><div className='text-left footer-link'>Home</div></Link>
                            </div>
                        }
                        
                        <div class="col-50 copyright-text">
                            Copyrights © All Rights Reserved by {config.company}.

                            <div class="copyright-links">
                                { isHome && <a href="/privacy">Privacy Policy</a> }
                            </div>
                        </div>
                        <div class="col-50 social-icons">
                            <a href="#" class="fa fa-facebook"></a>
                            <a href="#" class="fa fa-twitter"></a>
                            <a href="#" class="fa fa-instagram"></a>
                            <a href="#" class="fa fa-linkedin"></a>
                        </div>
                    </div>
                </div>
            </div> 
        </footer>
    );
};

export default Footer;