import React from "react";
import './PrivacyPage.css';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import config from "../../config";

function PrivacyPage() {

    return (
        <div>

            <Navbar isHome={false} />
            
            
            <section id="content">
                <div class="content-wrap pt-0">

                    <div id="section-privacy" class="section page-section bg-transparent pt-0 clearfix">
                        <div class="container clearfix text-white-50">

                            <div class="mb-5 dark padding-vertical-20">
                                <h2 className="gradient">{config.company} Customers Privacy Policy</h2>
                            </div>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>General</strong></p>
                            <p>{config.company}(“<strong>we</strong>” “<strong>us</strong>” “<strong>our</strong>”) is committed to protecting and respecting your privacy. We are the data controller and will process your personal data in accordance with the Data Protection Act 1998 as amended or replaced by the <strong>General Data Protection Regulation 2016</strong> and any national laws which relate to the processing of personal data (“<strong>data protection legislation</strong>”).</p>
                            <p>Please read the following carefully to understand our views and practices regarding Your Data and how we will treat it.</p>
                            <p>This policy applies to information we collect about:</p>
                            <ul>
                                <li>Visitors to our website(s)</li>
                                <li>Clients</li>
                                <li>Introducers</li>
                            </ul>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Visitors to our websites</strong></p>
                            <p>We may collect and process personal data about you in the following circumstances:</p>
                            <ul class="inset-box">
                                <li>
                                    when you complete the online contact forms on our website (“Site”) providing us with your name, address, email address and contact number;
                                </li>
                                <li>
                                    whenever you provide information to us when reporting a problem with our Site, making a complaint, making an enquiry or contacting us for any other reason. If you contact us, we may keep a record of that correspondence;
                                </li>
                                <li>
                                    when you visit our Site we will retain details such as traffic data, location data, weblogs and other communication data, and the resources that you access (see section 2.2.2 on Cookies below); and
                                </li>
                                <li>
                                    whenever you disclose your information to us, or we collect information from you in any other way, through our Sites.
                                </li>
                            </ul>
                            <p>We may also collect data in the following ways:</p>
                            <ul class="inset-box">
                                <li type="none" class="secondary-text-color"><strong>
                                    IP Address
                                </strong></li>

                                <li>
                                    We may collect information about your device, including where available your Internet Protocol address, for reasons of fraud protection. We may also collect information about your device’s operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users' browsing actions and patterns and does not identify any individual.
                                </li>

                                <li type="none" class="secondary-text-color"><strong>
                                    Cookies
                                </strong></li>

                                <li>
                                    A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
                                </li>

                                <li>
                                    We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
                                </li>

                                <li>
                                    Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
                                </li>

                                <li>
                                    You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
                                </li>

                                <li>
                                    We may use your personal data for our legitimate interests in order to:
                                </li>
                                <li>
                                    provide you with information, or services that you requested from us;
                                </li>
                                <li>
                                    respond to an enquiry submitted via our online contact forms;
                                </li>
                                <li>
                                    allow you to participate in interactive features of our Sites, when you choose to do so;
                                </li>
                                <li>
                                    ensure that content from our Sites are presented in the most effective manner for you and for your device;
                                </li>
                                <li>
                                    improve our Sites and services;
                                </li>
                                <li>
                                    process and deal with any complaints or enquiries made by you; and
                                </li>
                                <li>
                                    contact you for marketing purposes where you have signed up for these (see section 7 for further details).
                                </li>
                            </ul>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Website links</strong></p>
                            <p>Our Site may, from time to time, contain links to and from the websites of third parties. Please note that if you follow a link to any of these websites, such websites will apply different terms to the collection and privacy of your personal data and we do not accept any responsibility or liability for these policies. Please check before you submit your information to these websites. We cannot guarantee that such links are virus free.</p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Clients</strong></p>
                            <p>
                                We will collect details such as name, address, email address, contact number, date of birth, national insurance number and financial information in order to provide services to customers. We may also receive details of credit checks undertaken where you have supplied these to us.
                            </p>
                            <p>
                                We will share customer personal information with our employees to manage our relationship with you and we will retain customer personal data typically for 6 years but indefinitely depending on the type of business you have transacted with us.
                            </p>
                            <p>
                                We will use your personal data provided to comply with our contractual obligations arising from the agreements we enter into with our Customers and share the data with financial institutions who can assist in the provision of financial services to customers including product providers, lenders, banks, insurers, fund managers and platform providers.
                            </p>
                            <p>
                                We will use customer personal data for our legitimate interests including:
                            </p>
                            <ul class="inset-box">
                                <li>
                                    sharing personal data with Tenet as they provide compliance services to us Further details regarding how Tenet will use customer personal data can be found on its website at <a class="invert " href="https://www.tenetgroup.co.uk" target="blank">www.tenetgroup.co.uk</a> or by contacting Tenet on 01132390011;
                                </li>
                                <li>
                                    with your consent, marketing our other products and services by mail and email; and
                                </li>
                                <li>
                                    with your consent, obtaining your sensitive personal data from third parties including your health, medical history (for life and illness cover),ethnic origin, or criminal prosecutions from third parties such as employers and credit reference agencies, fraud prevention agencies and other similar organisations.
                                </li>
                            </ul>
                            <p>We will not transfer any customer personal data outside the European Economic Area (“<strong>EEA</strong>”). </p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Marketing</strong></p>
                            <p>We may use customer personal data to provide you with details about our services, products, business updates and events which we think may be of interest.  </p>
                            <p>You have the right to opt-out of receiving the information detailed in section 7.1 at any time. To opt-out of receiving such information you can:</p>
                            <ul class="inset-box">
                                <li>
                                    tick the relevant box situated in the form on which we collect your information;
                                </li>
                                <li>
                                    clicking the unsubscribe button contained in any such communication received; or
                                </li>
                                <li>
                                    email us at enquiries@my-top-pensions.co.uk or call 0800 03 04 008 or local 01803 211214 providing us with your name and contact details.
                                </li>
                            </ul>
                            <p>Where you have subscribed to receive marketing correspondence from us we will keep your personal data from when you subscribed to receiving marketing information from us and became a client utilising our services, until you unsubscribe from receiving such correspondence from us (whichever is earlier).&nbsp;</p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Legal basis for processing your personal data</strong></p>
                            <p>
                                In accordance with data protection legislation we are required to notify you of the legal basis upon which we process your personal data. We process your personal data for the following reasons:
                            </p>
                            <ul class="inset-box">
                                <li>
                                    for performance of a contract we enter into with you;
                                </li>
                                <li>
                                    where necessary for compliance with a legal obligation we are subject to; and
                                </li>
                                <li>
                                    for our legitimate interests (as described within this policy).
                                </li>
                            </ul>
                            <p>
                                We will also process your personal data including personal sensitive data where we have obtained your explicit consent.
                            </p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Disclosure of your data to third parties</strong></p>
                            <p>
                                In addition to the third parties mentioned previously in this policy, we may disclose your personal data to third parties for the following legitimate business purposes:
                            </p>
                            <ul class="inset-box">
                                <li>
                                    staff members in order to facilitate the provision of services to you;
                                </li>
                                <li>
                                    IT software providers that host our website and store data on our behalf; and
                                </li>
                                <li>
                                    to a prospective buyer of some or all of our business or assets, in which case personal data including Your Data will also be one of the transferred assets.
                                </li>
                            </ul>
                            <p>
                                We may disclose your personal data to the police, regulatory bodies, legal advisors or similar third parties where we are under a legal duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our agreements; or to protect our rights, property, or safety of our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.
                            </p>
                            <p>
                                We will not sell or distribute your personal data to other organisations without your approval.
                            </p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Cross-border data transfers</strong></p>
                            <p>We do not transfer personal data outside the EEA.&nbsp; Where this is required in the future, we will ensure that safeguards are in place so that such transfers comply with data protection legislation.</p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Data security</strong></p>
                            <p>
                                Information you provide to us is shared on our secure servers. We have implemented appropriate physical, technical and organisational measures designed to secure your information against accidental loss and unauthorised access, use, alteration or disclosure. In addition, we limit access to personal data to those employees, agents, contractors and other third parties that have a legitimate business need for such access.]
                            </p>
                            <p>
                                Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Sites, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
                            </p>
                            <p>
                                Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your information transmitted to our Site; any transmission is at your own risk.
                            </p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Access to, updating, deleting and restricting use of your data</strong></p>
                            <p>
                                It is important that the personal data we hold about you is accurate and current.  Please keep us informed if the personal data we hold about you changes.
                            </p>
                            <p>
                                Data protection legislation gives you certain rights in relation to your personal data.  You have the right to object to the processing of your personal data in certain circumstances and to withdraw your consent to the processing of your personal data where this has been provided.
                            </p>
                            <p>
                                You can also ask us to undertake the following:
                            </p>
                            <ul class="inset-box">
                                <li>
                                    update or amend your personal data if you feel this is inaccurate;
                                </li>
                                <li>
                                    remove your personal data from our database entirely;
                                </li>
                                <li>
                                    send you copies of your personal data in a commonly used format and transfer your information to another entity where you have supplied this to us, and we process this electronically with your consent or where necessary for the performance of a contract;
                                </li>
                                <li>
                                    restrict the use of your personal data; and
                                </li>
                                <li>
                                    provide you with access to information held about you and for this to be provided in an intelligible form.
                                </li>
                            </ul>
                            <p>
                                We may request specific information from you to help us confirm your identity.  Data protection legislation may allow or require us to refuse to provide you with access to some or all the personal data that we hold about you or to comply with any requests made in accordance with your rights referred to above. If we cannot provide you with access to your personal data, or process any other request we receive, we will inform you of the reasons why, subject to any legal or regulatory restrictions.
                            </p>
                            <p>
                                Please send any requests relating to the above to us specifying your name and the action you would like us to undertake. Note that in relation to requests to access personal data, we reserve the right to charge a reasonable fee to comply with your request.
                            </p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Links to other websites</strong></p>
                            <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Right to withdraw consent</strong></p>
                            <p>Where you have provided your consent to the collection, processing and transfer of your personal data, you may withdraw that consent at any time. This will not affect the lawfulness of data processing based on consent before it is withdrawn. To withdraw your consent please contact us at Rockwood House, Princes Point, Parkhill Road, Torquay TQ1 2DU.</p>

                            <p class="font-body ls1 text-uppercase policy-heading"><strong>Changes to our privacy policy</strong></p>
                            <p>We reserve the right to update this privacy policy at any time, and any changes we make to our privacy policy will be posted on this page. We will notify you if there are any changes to this policy that materially affect how we collect, store or process your personal data. If we would like to use your previously collected personal data for different purposes than those we notified you about at the time of collection, we will provide you with notice and, where required by law, seek your consent, before using your personal data for a new or unrelated purpose. We may process your personal without your knowledge or consent where required by applicable law or regulation.</p>

                            <p>I HEREBY AUTHORISE {config.company} TO USE MY SIGNATURE TO OBTAIN INFORMATION ON MY PENSION USING A WRITTEN LETTER OF AUTHORITY. I AGREE TO TRANSFER THESE PENSIONSs TO {config.company} AND CONFIRM THAT I HAVE READ, UNDERSTOOD AND AGREE TO THE TERMS, INCLUDING THE DECLARATIONS, DATA PROTECTION AND TRANSFER AUTHORISATIONS THEREIN.</p>

                        </div>
                    </div>

                </div>
            </section>

            <Footer isHome={false} />
        </div>
    );
}

export default PrivacyPage;