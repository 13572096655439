// pages/home/HomePage.js
import React, {useRef} from 'react';
import './HomePage.css';
import Navbar from '../../components/navbar/Navbar';
import config from '../../config';
import SplitContent from '../../components/split-content/SplitContent';
import TestItem from '../../components/test-item/TestItem';
// import Combine from '../../components/combine/Combine';

import Carousel from "../../components/carousel/carousel";
import ContactBanner from '../../components/contact-banner/ContactBanner';
import FloatingTextImage from '../../components/floating-text-image/FloatingTextImage';
import ContactForm from '../../components/contact-form/ContactForm';
import Footer from '../../components/footer/Footer';

const items = [
  { id: 1, color: 'da', title: 'Our achievements',  image: 'achievement.png',  text: `Since inception, the company has enjoyed steady growth developed by giving quality advice and managing consistent performance of clients’ portfolios, which range between £100,000 and £5,000,000 in value across both Pension and Investments.` },
  { id: 2, color: 'da', title: 'What we do', image: 'do.png', text: `${config.company} provides a simplified non-advised Pension consolidation service. If you would like to take advice, <a target='_blank' href="https://www.pension-drawdown.co.uk">The Pension Drawdown Company</a> provides specialist pension, investment and tax-planning advice for individuals who are either saving for retirement or taking benefits from their Pensions and Investments as part of their retirement plan.` },
  { id: 3, color: 'da', title: 'Making life easier',   image: 'easier.png',    text: `As independent and whole-of-market financial planners, we strongly believe in making your financial planning experience simple, pleasant and rewarding, and MyPension is an example of our commitment to giving clients quality solutions with leading industry providers.` }
];

function HomePage() {
  const navRefs = {
    home    : useRef(null),
    about   : useRef(null),
    contact : useRef(null)
  };

  return (
    <div ref={navRefs.home}>
      <Navbar navRefs={navRefs} isHome={true}/>

        <section className='home-banner flex flex-horizontal-center flex-vertical-center kenburns-bottom '>
            {
                config.logo !== ""
                    ? <img className="banner-image" alt="banner background" src={config.logo}/>
                    : ""
            }
            <h1>{config.company}</h1>
        </section>


        <section className='bg-ds'>

        <Carousel contactRef={navRefs.contact} />
        
        <ContactBanner contactRef={navRefs.contact} />
        
      </section>

      <section ref={navRefs.about} className='bg-ds about-section padding-vertical-40' name="about-section" id="about-section">
        <div className='container text-center'>
          <h2 className='text-ls'>About us</h2>

          <p className='about-text narrow text-center text-la'>
            {config.company} is owned by the award winning “The Pension Drawdown Company” 
            which is an independent Financial Planning firm established in 1999. 
            It is authorised and regulated by the Financial Conduct Authority. 
            This is a non-advised service therefore the information on this website 
            should not be construed as individual advice to transfer your existing Pensions.
          </p>

          <SplitContent items={items} SubComponent={FloatingTextImage} sectionName="test-items" splitClass="col-33" />
        
          <p className='about-text narrow text-center text-da'>
            The Financial Ombudsman Service is available to sort out individual 
            complaints that clients and financial services businesses aren’t able 
            to resolve themselves. To contact the Financial Ombudsman Service please visit &nbsp;
            <a target="_blank" href="www.financial-ombudsman.org.uk">www.financial-ombudsman.org.uk</a>
          </p>

        </div>
      </section>

      <section ref={navRefs.contact} className='bg-ls' name="contact-section" id="contact-section">

        <ContactForm />

      </section>
      
      <Footer navRefs={navRefs} isHome={true} />
    </div>
  );
}

function Kachow(){
  fetch('http://slim-react-template.test/api/data').then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }).then(data => {
    // Handle the data returned from the backend
    console.log(data);
  }).catch(error => {
    // Handle errors
    console.error('Error fetching data:', error);
  });

}

export default HomePage;
