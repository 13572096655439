import React from 'react';

const SplitContent = ({items, SubComponent, sectionName, splitClass}) => {
  return (
    <section name={sectionName}>
        <div className="flex-row">
            {items.map((item, index) => (
                <div className={splitClass}>
                    <SubComponent key={index} data={item} />
                </div>
            ))}
        </div>
    </section>
  );
}

export default SplitContent;