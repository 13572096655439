import React, {useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ navRefs, isHome }) => {
  
  const navItemRefs = {
    home    : useRef(null),
    about   : useRef(null),
    contact : useRef(null)
  };

  useEffect(() => {

    const homeClicked    = () => scrollTo(navRefs.home.current, fast);
    const aboutClicked   = () => scrollTo(navRefs.about.current, fast);
    const contactClicked = () => scrollTo(navRefs.contact.current, fast);
    
    const homeRef    = navItemRefs.home.current;
    const aboutRef   = navItemRefs.about.current;
    const contactRef = navItemRefs.contact.current;

    if (homeRef)    homeRef.addEventListener('click', homeClicked);
    if (aboutRef)   aboutRef.addEventListener('click', aboutClicked);
    if (contactRef) contactRef.addEventListener('click', contactClicked);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      if (homeRef)    homeRef.removeEventListener('click', homeClicked);
      if (aboutRef)   aboutRef.removeEventListener('click', aboutClicked);
      if (contactRef) contactRef.removeEventListener('click', contactClicked);
    };
  }, []);

  return (
    <nav >
      { isHome ? 
        <div className='flex flex-horizontal-center container'>
          <Link className='flex-grow-1' to="/"><div ref={navItemRefs.about} className='nav-item text-center'>ABOUT</div></Link>
          <Link className='flex-grow-1' to="/"><div ref={navItemRefs.contact} className='nav-item text-center'>CONTACT</div></Link>
        </div>
        :
        <div className='flex flex-horizontal-center container'>
          <Link className='flex-grow-1 text-center' to="/"><div className='nav-item text-center'>HOME</div></Link>
        </div>
      }
    </nav>
  );
}

// const fast = 4269;
const fast = 500;
const slow = 13000;
const effect = easeInOutCuaic;
/* Functions to make scroll with speed control */
function scrollToTop(){
  var e = document.documentElement;
  scrollTo(0, fast);
}

function scrollToAbout(){
  var e = document.documentElement;
  scrollTo(e.scrollHeight - e.clientHeight, fast);
}

// Element to move + duration in milliseconds
export const scrollTo = (element, duration) => {
  var e = document.documentElement;
  if (e.scrollTop === 0) {
    var t = e.scrollTop;
    ++e.scrollTop;
    e = t + 1 === e.scrollTop-- ? e : document.body;
  }
  scrollToC(e, e.scrollTop, element, duration);
}

// Element to move, element or px from, element or px to, time in ms to animate
function scrollToC(element, from, to, duration) {
  if (duration <= 0) return;
  if (typeof from === "object") from = from.offsetTop;
  if (typeof to === "object") to = to.offsetTop;
  scrollToX(element, from, to, 0, 1 / duration, 20, effect);
}

function scrollToX(element, xFrom, xTo, t01, speed, step, motion) {
  if (t01 < 0 || t01 > 1 || speed <= 0) {
    element.scrollTop = xTo;
    return;
  }
  element.scrollTop = xFrom - (xFrom - xTo) * motion(t01);
  t01 += speed * step;

  setTimeout(function() {
    scrollToX(element, xFrom, xTo, t01, speed, step, motion);
  }, step);
}

/* Effects Functions */
function linearTween(t) {
  return t;
}

function easeInQuad(t) {
  return t * t;
}

function easeOutQuad(t) {
  return -t * (t - 2);
}

function easeInOutQuad(t) {
  t /= 0.5;
  if (t < 1) return t * t / 2;
  t--;
  return (t * (t - 2) - 1) / 2;
}

function easeInCuaic(t) {
  return t * t * t;
}

function easeOutCuaic(t) {
  t--;
  return t * t * t + 1;
}

function easeInOutCuaic(t) {
  t /= 0.5;
  if (t < 1) return t * t * t / 2;
  t -= 2;
  return (t * t * t + 2) / 2;
}

function easeInQuart(t) {
  return t * t * t * t;
}

function easeOutQuart(t) {
  t--;
  return -(t * t * t * t - 1);
}

function easeInOutQuart(t) {
  t /= 0.5;
  if (t < 1) return 0.5 * t * t * t * t;
  t -= 2;
  return -(t * t * t * t - 2) / 2;
}

function easeInQuint(t) {
  return t * t * t * t * t;
}

function easeOutQuint(t) {
  t--;
  return t * t * t * t * t + 1;
}

function easeInOutQuint(t) {
  t /= 0.5;
  if (t < 1) return t * t * t * t * t / 2;
  t -= 2;
  return (t * t * t * t * t + 2) / 2;
}

function easeInSine(t) {
  return -Math.cos(t / (Math.PI / 2)) + 1;
}

function easeOutSine(t) {
  return Math.sin(t / (Math.PI / 2));
}

function easeInOutSine(t) {
  return -(Math.cos(Math.PI * t) - 1) / 2;
}

function easeInExpo(t) {
  return Math.pow(2, 10 * (t - 1));
}

function easeOutExpo(t) {
  return -Math.pow(2, -10 * t) + 1;
}

function easeInOutExpo(t) {
  t /= 0.5;
  if (t < 1) return Math.pow(2, 10 * (t - 1)) / 2;
  t--;
  return (-Math.pow(2, -10 * t) + 2) / 2;
}

function easeInCirc(t) {
  return -Math.sqrt(1 - t * t) - 1;
}

function easeOutCirc(t) {
  t--;
  return Math.sqrt(1 - t * t);
}

function easeInOutCirc(t) {
  t /= 0.5;
  if (t < 1) return -(Math.sqrt(1 - t * t) - 1) / 2;
  t -= 2;
  return (Math.sqrt(1 - t * t) + 1) / 2;
}


export default Navbar;
