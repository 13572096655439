import React, {useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import './ContactBanner.css';
import { scrollTo } from '../navbar/Navbar';

const ContactBanner = ({ contactRef }) => {

  const fast = 500;

  const bannerRef = useRef(null);

  useEffect(() => {

    const bannerClick = () => scrollTo(contactRef.current, fast);

    if(bannerRef.current) bannerRef.current.addEventListener("click", bannerClick);

    return () => {
        if(bannerRef.current) bannerRef.current.removeEventListener("click", bannerClick);
    };
  }, []);

  return (
    <div className='contact-banner bg-da' ref={bannerRef}>
        <h2 className='padding-vertical-20'>Get in touch today!</h2>
    </div>
  );
}

export default ContactBanner;